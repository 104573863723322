
import {Options, Vue} from 'vue-class-component';
import ProductionTemplate from "@/components/ProductionTemplate.vue";
import ProductIntroduceData from "@/types/ProductIntroduceData";

@Options<Xiezuocat>({
  components: {
    ProductionTemplate
  },
  props: {}
})
export default class Xiezuocat extends Vue {
  introduce:ProductIntroduceData = {
    name:'秘塔写作猫',
    profile:'AI写作，文章自成',
    buttons:{
      experience:'https://xiezuocat.com/#/',
      videoIntroduce:'https://uranus-static.oss-cn-beijing.aliyuncs.com/video/AI%E5%86%99%E4%BD%9C%E4%BB%8B%E7%BB%8D.mp4'
    },
    features:[
      {
        svgIconName: 'search',
        feature:{
          title:'AI内容创作',
          desc:[
              '自研大规模语言模型，提供最流畅的生成效果',
              '提供各类AI写作模板，帮助你写报告、写营销文案，头脑风暴等',
              '交互式文本编辑环境，随时随地快速续写',
              '由AI贯彻执行您的创作要求和指令'
          ]
        }
      },
      {
        svgIconName: 'lap',
        feature:{
          title:'篇章级理解的纠错能力',
          desc:[
            '独有的NLP纠错算法技术，智能联系上下文进行纠错',
            '中文纠错支持：易混淆词改错、形近字改错、音近字改错、病句检错、 日期错误、金额错误、标点符号错误、领导人称谓不匹配等数十项检查',
            '英文纠错支持：语法错误、词汇拼写、标点错误、冠词检查等十余项检查',
          ]
        }
      },
      {
        svgIconName: 'diamonds',
        feature:{
          title:'支持多种使用场景',
          desc:[
              '网页版支持在线协作，还特有政治敏感词、商务助手功能',
              '支持API对接，为您的产品增加强大的语义分析能力',
              '安装浏览器插件，在各类网站、在线文档工具内进行纠错',
              '安装Word插件，在Word内实现文本纠错',
              '搜索小程序“秘塔写作猫”、“量子速写”，在手机端进行纠错、写作'
          ]
        }
      },
      {
        svgIconName: 'global',
        feature:{
          title: '流畅改写、高效翻译',
          desc: [
              '语义改写模型，自动句子改写润色',
              '全文翻译，适用于论文、商务等场景'
          ]
        }
      }
    ],
    psText:'咨询联系'
  }
}
